import React from "react";

import ContentBlocks from "../components/ContentBlocks/ContentBlocks";
import Layout from "../components/Layout/Layout";
import SEO from "../components/seo";

const IndexPage = ({ pageContext }) => {
  const {
    meta_title,
    meta_description,
    menu,
    lang = "es",
    footer,
    blocks,
    cookie_message_button_text,
    cookie_message_text,
    posts_no_future = []
  } = pageContext;

  const lastBlockName =
    blocks && blocks.length > 0
      ? blocks[blocks.length - 1].slice_type
        ? blocks[blocks.length - 1].slice_type.charAt(0).toUpperCase() +
          blocks[blocks.length - 1].slice_type.slice(1)
        : null
      : null;

  return (
    <Layout
      className={`${
        ["Call_to_action", "Hub_posts"].includes(lastBlockName)
          ? "without-footer-decorator"
          : ""
      }`}
      lang={lang}
      menu={menu}
      footer={footer}
      cookie_message_text={cookie_message_text}
      cookie_message_button_text={cookie_message_button_text}
    >
      <SEO lang={lang} title={meta_title} description={meta_description} />
      {blocks.map((block, index) => {
        if (!block || !block.slice_type) return;

        const { slice_type } = block;
        const componentName = slice_type
          ? slice_type.charAt(0).toUpperCase() + slice_type.slice(1)
          : null;
        return (
          <ContentBlocks
            tag={componentName}
            key={slice_type + index}
            lang={lang}
            {...block}
            {...(componentName === "Hub_posts"
              ? { posts: posts_no_future }
              : {})}
          />
        );
      })}
    </Layout>
  );
};

export default IndexPage;
